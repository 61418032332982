







































































import {
  computed,
  defineComponent,
  PropType,
  provide,
  ref,
  toRefs,
  nextTick
} from "vue";
import { getVatRateTypes } from "@/api/infoteka";
import { ListItemInterface } from "@/models/global/ListItem.interface";
import { OrganizationTypeEnum } from "@/models/autocomplete/OrganizationType.enum";
import InputMask from "@/components/UI/InputMask.vue";
import OrderOptions from "@/components/order/OrderOptions.vue";
import PersonItem from "@/components/personItem/index.vue";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import Dimensions from "@/components/order/places/Dimensions.vue";
import PlaceItemFtl from "@/components/order/places/PlaceItemFtl.vue";
import useVuelidate from "@vuelidate/core";
import OrderService from "@/models/order/Order.service";
import { useOrderFieldsInput } from "@/components/order/useOrderFieldsInput";
import ExecutorOrganization from "@/components/order/ExecutorOrganization.vue";
import ForwardingOptions from "@/components/order/ForwardingOptions.vue";
import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";
import { LoadingAddressObjectInterface } from "@/models/order/AddressObject.interface";
import { parseDateString } from "@/utils/dateUtils";
import { required } from "@vuelidate/validators";

export default defineComponent({
  name: "OrderItemFtl",
  components: {
    ForwardingOptions,
    ExecutorOrganization,
    PlaceItemFtl,
    Dimensions,
    PersonItem,
    OrderOptions,
    InputMask
  },
  props: {
    order: {
      type: Object as PropType<OrderItemInterface>,
      required: true
    },
    validateAfterMount: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup(props) {
    const { order } = toRefs(props);
    const orderService = new OrderService();

    const loadingDates = computed(() => {
      return order.value.places.flatMap((x: PlaceItemInterface) =>
        x.loadingAddresses
          .map((y: LoadingAddressObjectInterface) => {
            if (!y.datePeriod.dateFrom || !y.datePeriod.hourFrom) return null;
            return parseDateString(
              y.datePeriod.dateFrom + " " + y.datePeriod.hourFrom,
              "yyyy-MM-dd HH:mm"
            );
          })
          .filter(x => x !== null)
          .sort()
      );
    });

    provide("loadingDates", loadingDates);

    const {
      onAssessedValueInput,
      onPlaceAssessedValueInput,
      onPlaceDimensionsInput,
      onDimensionsInput,
      onPriceInput
    } = useOrderFieldsInput(order);

    const taxesTypes = ref<ListItemInterface[]>([]);
    const loadTaxesTypes = async () => {
      taxesTypes.value = await getVatRateTypes();
    };

    const v$ = useVuelidate({}, order);

    const addPlace = async () => {
      orderService.addPlaceToOrder(order.value);

      await nextTick();
      v$.value.$touch();
    };

    return {
      taxesTypes,
      v$,
      onDimensionsInput,
      onPlaceDimensionsInput,
      onAssessedValueInput,
      onPlaceAssessedValueInput,
      onPriceInput,
      addPlace,
      loadTaxesTypes
    };
  },
  mounted() {
    this.loadTaxesTypes();
    this.fetchOrganization({ type: OrganizationTypeEnum.Customer });

    if (!this.validateAfterMount) return;

    this.v$.$touch();
  },
  methods: {
    fetchOrganization(data: { type: OrganizationTypeEnum; parentId?: string }) {
      this.$store.dispatch("autocomplete/fetchOrganization", data);
    }
  }
});
