














































import BaseInput from "@/components/UI/BaseInput.vue";
import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";
import LoadingAddressObj from "@/components/order/addresses/LoadingAddressObj.vue";
import { required } from "@vuelidate/validators";
import { computed, defineComponent, nextTick, PropType, toRef } from "vue";
import useVuelidate from "@vuelidate/core";
import Dimensions from "@/components/order/places/Dimensions.vue";
import InputMask from "@/components/UI/InputMask.vue";
import { LoadingAddressObjectInterface } from "@/models/order/AddressObject.interface";

export default defineComponent({
  name: "PlaceItemFtl",
  components: {
    Dimensions,
    LoadingAddressObj,
    BaseInput,
    InputMask
  },
  props: {
    idx: { type: Number as PropType<number>, required: true },
    place: {
      type: Object as PropType<PlaceItemInterface>,
      required: true
    },
    showRemove: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    readonlyDimensions: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    readonlyAssessedValue: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ["remove"],
  setup(props) {
    const place = toRef(props, "place");
    const v$ = useVuelidate(
      {
        loadingAddresses: {
          required
        }
      },
      place
    );

    const addLoadingAddress = async () => {
      place.value.addLoadingAddress();

      await nextTick();
      v$.value.$touch();
    };

    return {
      v$,
      addLoadingAddress,
      tasksCount: computed<number>(() => {
        let tasksCount = 0;

        place.value.loadingAddresses.forEach(
          (loadingAddress: LoadingAddressObjectInterface) => {
            tasksCount += loadingAddress.unloadingAddresses.length;
          }
        );

        return tasksCount;
      })
    };
  }
});
