


































































































import { ListItemInterface } from "@/models/global/ListItem.interface";
import {
  getBodyTypes,
  getCargoCategories,
  getLoadingType
} from "@/api/infoteka";
import { defineComponent, PropType } from "vue";
import { OrderOptionsItemInterface } from "@/models/order/OrderOptions.interface";
import BaseInput from "@/components/UI/BaseInput.vue";
import useVuelidate from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";

export default defineComponent({
  name: "OrderOptions",
  components: { BaseInput },
  props: {
    options: {
      type: Object as PropType<OrderOptionsItemInterface>,
      required: true
    }
  },
  setup(props) {
    const v$ = useVuelidate(
      {
        bodyTypes: {
          required,
          minLength: minLength(1)
        }
      },
      props.options
    );

    return {
      v$
    };
  },
  data() {
    return {
      cargoCategories: [] as ListItemInterface[],
      bodyTypes: [] as ListItemInterface[],
      loadingTypes: [] as ListItemInterface[],
      temperatureRegimes: [
        {
          id: "Без режима",
          title: "Без режима"
        },
        {
          id: "2 — 6 °C",
          title: "2 — 6 °C"
        },
        {
          id: "12 — 18 °C",
          title: "12 — 18 °C"
        },
        {
          id: "5 — 25 °C",
          title: "5 — 25 °C"
        },
        {
          id: "0 — -5 °C",
          title: "0 — -5 °C"
        },
        {
          id: "-18 °C",
          title: "-18 °C"
        },
        {
          id: "-25 °C",
          title: "-25 °C"
        }
      ] as ListItemInterface[]
    };
  },
  computed: {
    bodyTypeModel: {
      get(): string {
        return this.options.bodyTypes || [];
      },
      set(value: string) {
        this.options.bodyTypes = value;
      }
    }
  },
  mounted() {
    this.loadList();
  },
  methods: {
    async loadList(): Promise<void> {
      Promise.all([
        getCargoCategories(),
        getBodyTypes(),
        getLoadingType()
      ]).then(dataList => {
        this.cargoCategories = dataList[0];
        this.bodyTypes = dataList[1];
        this.loadingTypes = dataList[2];
      });
    }
  }
});
